import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "A short guide to help you pick the correct HTML tag",
  "description": "A semantically correct HTML tag informs the browser and the developer about its content. It also helps keyboards and assistive technologies to navigate through a website.",
  "lead": "A semantically correct HTML tag informs the browser and the developer about its content. It also helps keyboards and assistive technologies to navigate through a website.",
  "dateCreated": "2021-09-28T00:00:00.000Z",
  "tags": "HTML",
  "slug": "a-short-guide-to-help-you-pick-the-correct-html-tag",
  "postUrl": "https://uselessdivs.com/blog/a-short-guide-to-help-you-pick-the-correct-html-tag/",
  "ogImg": "https://uselessdivs.com/img/a-short-guide-to-help-you-pick-the-correct-html-tag/og-img.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`There are `}<em parentName="p">{`more than 100 HTML tags`}</em>{`, but which ones are semantic and which are not?`}<br />{`
`}<strong parentName="p">{`Non-semantic elements:`}</strong>{` div and span, etc. Unlike semantic elements, these are not holding any extra information about their content.`}<br />{`
`}<strong parentName="p">{`Semantic elements:`}</strong>{` header, main, footer, article, img, p, h1, etc.`}</p>
    <p>{`When you create a new HTML file, you have something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        ...
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        ...
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`I will focus on `}<strong parentName="p">{`what's inside the body element`}</strong>{` and name only the most important tags. You can find a link with a full list of HTML elements at the end of this article.`}</p>
    <p>{`The HTML tags can be separated into different groups and it helped me to understand the whole concept and I think this is the best way to explain it, so let's start.`}</p>
    <h2>{`Content sectioning or landmarks`}</h2>
    <p>{`The layout of the main page of this blog looks like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/a-short-guide-to-help-you-pick-the-correct-html-tag/layout.svg",
        "alt": "A visual representation of the main elements of the website, such as header, nav, main, section, article, aside."
      }}></img></p>
    <h3>{`header and nav`}</h3>
    <p>{`In my `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<header>`}</code>{`, you can find the logo, which is a link and it leads to the main page and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<nav>`}</code>{` element which contains the links to my other pages.
You can also put here some `}<strong parentName="p">{`introduction about the page`}</strong>{`, a search bar, or other elements.`}</p>
    <h3>{`main`}</h3>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<main>`}</code>{` element holds different content on each page. This `}<strong parentName="p">{`holds the primary information`}</strong>{` of the current page `}<strong parentName="p">{`or the main functionality`}</strong>{` of an application.`}</p>
    <h3>{`footer`}</h3>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<footer>`}</code>{`tag creates a footer for a section or for the whole document. You can have multiple `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<footer>`}</code>{` elements in one page, which typically contains data, like: contact info to the author, copyrigt information, related links etc.`}</p>
    <h3>{`aside`}</h3>
    <p>{`You can also find the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<aside>`}</code>{` on the right side of my page. It `}<strong parentName="p">{`holds complementary content`}</strong>{`. If I remove it, nothing will be missing from the primary content. You can put here for example table of contents, related posts, etc.`}</p>
    <h3>{`section`}</h3>
    <p>{`Inside my `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<main>`}</code>{` element, you can find the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<section>`}</code>{` tag. If there isn't a more specific HTML element for a part of your document, you can group related elements with this tag. This element should `}<strong parentName="p">{`always`}</strong>{` have a heading.`}</p>
    <h3>{`h1 - h6`}</h3>
    <p>{`Heading elements: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h1>`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h2>`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h3>`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h4>`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h5>`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h6>`}</code>{`, are the titles of the different sections. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h1>`}</code>{` is the highest level of the headings, and it is necessary to have one on each page but there should be only one per page. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h6>`}</code>{` is the lowest. If you run out of headings, there is another option: `}<a parentName="p" {...{
        "href": "/blog/the-7th-heading/"
      }}>{`the 7th heading`}</a></p>
    <p>{`The reason you should use landmarks is that screen readers and other `}<em parentName="p">{`assistive technologies can jump back and forth between these elements`}</em>{`.
For example, all the pages have the same header with the same logo, the same navigation, but with a keyboard shortcut, you can jump over them and start with the content of the main tag.`}</p>
    <h2>{`Text content elements`}</h2>
    <p>{`These can help you `}<strong parentName="p">{`organize the written content`}</strong>{` on your pages and give information about the content to search engines and screen readers.`}</p>
    <h3>{`P`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<p>`}</code>{` stands for paragraph. In other words, a block of text. You will use it a lot for wrapping your text content with it.`}</p>
    <h3>{`ul, ol, li`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<ul>`}</code>{` - unordered list, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<ol>`}</code>{` - ordered list, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<li>`}</code>{` - list elements of the two kind of lists. If you need to create a list you have to use them.`}</p>
    <p>{`List items are wrapped in `}<em parentName="p"><code parentName="em" {...{
          "className": "language-text"
        }}>{`<ul>`}</code>{`, if the order is not important`}</em>{`. It will put bullet points before each list item.`}</p>
    <p><em parentName="p">{`Choose `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`<ol>`}</code>{`, if the order of your list items is important.`}</em>{` By default, it will put a number before each item in ascending order.`}</p>
    <h3>{`Figure, figcaption, blockquote, cite`}</h3>
    <p>{`I will explain all of them with one example. Look at this block of code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`figure`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`blockquote`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`cite`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://www.htmhell.dev/24-a-placeholder-is-not-a-label/`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        Every form input element needs a label.
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`blockquote`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`figcaption`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Manuel Matuzović, `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`cite`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`A placeholder is not a label - HTMHell`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`cite`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`figcaption`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`figure`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<figure>`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<figcaption>`}</code>{` go hand in hand
The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<figure>`}</code>{` is a self-contained element optionally, but usually with a caption. This caption is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<figcaption>`}</code>{` element.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<blockquote>`}</code>{` is for quotations, where you want to indicate the author, the source, etc. (You can use the cite attribute on this tag to give the URL of the source of this quotation).
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<cite>`}</code>{` element `}<em parentName="p">{`is holding the reference to a quoted content`}</em>{`. Name of the author, title of the cited material, etc. and this is the first element of our next group, the inline text elements.`}</p>
    <h2>{`Inline text elements`}</h2>
    <p>{`You can wrap smaller parts of text like words, sentences, etc into different tags to define its style, meaning, or structure.`}</p>
    <h3>{`a`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<a>`}</code>{` or anchor elements are links, which have a `}<strong parentName="p">{`href`}</strong>{` attribute, which leads to `}<em parentName="p">{`other web pages, files, email addresses, phone numbers, or a location on the same page`}</em>{`.`}</p>
    <h3>{`em`}</h3>
    <p>{`Use this element to `}<strong parentName="p">{`emphasize any text content.`}</strong>{` `}<em parentName="p">{`Screen readers read this part of a text in a different way.`}</em>{` Browsers render the content of this text in italic font style but don't use it only for styling.`}</p>
    <h3>{`strong`}</h3>
    <p>{`Use this element to `}<strong parentName="p">{`mark content, which is more important`}</strong>{` than the rest of the text. `}<strong parentName="p">{`Screen readers read this part of a text in a different way.`}</strong>{` Browsers render the content of this text in bold font style but don't use it only for styling.`}</p>
    <h3>{`q`}</h3>
    <p>{`A short inline quotation, which doesn't need an entire paragraph. (You can use the cite attribute on this tag to give the URL of the source of this quotation)`}</p>
    <h2>{`Image and multimedia`}</h2>
    <h3>{`audio`}</h3>
    <p>{`audio is used to embed sound into your website.`}</p>
    <h3>{`img`}</h3>
    <p>{`img is used to embed an image into your website.`}</p>
    <h3>{`video`}</h3>
    <p>{`video is used to embed a video into your website.`}</p>
    <h2>{`Table content`}</h2>
    <p>{`I think an example is the easiest way to explain these elements:`}</p>
    <iframe height="300" style={{
      "width": "100%"
    }} scrolling="no" title="Table" src="https://codepen.io/polgarj/embed/vYZvwrQ?default-tab=html%2Cresult" frameBorder="no" loading="lazy" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href="https://codepen.io/polgarj/pen/vYZvwrQ">
  Table</a> by Jozsef Polgar (<a href="https://codepen.io/polgarj">@polgarj</a>)
  on <a href="https://codepen.io">CodePen</a>.
    </iframe><br /><br />
    <h3>{`table`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<table>`}</code>{` creates a two-dimensional table with rows and columns`}</p>
    <h3>{`thead`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<thead>`}</code>{` is a row or rows that define the column heading`}</p>
    <h3>{`th`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<th>`}</code>{` is a single cell as a header of a group of cells. This group of cells can be a column or a row, and you have to define it by the scope attribute. The scope attribute can have the value of col or row.`}</p>
    <h3>{`tbody`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<tbody>`}</code>{` wraps the main content of our table, which is usually between the thead and the tfoot elements.`}</p>
    <h3>{`tr`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<tr>`}</code>{` defines a row of cells in a table.`}</p>
    <h3>{`td`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<td>`}</code>{` stands for table data. This is a single cell that holds the information.`}</p>
    <h3>{`tfoot`}</h3>
    <p>{`This is a row at the end of the table, that summarizes its content.`}</p>
    <h2>{`Forms`}</h2>
    <h3>{`form`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<form>`}</code>{` element wraps the entire form that users can use to enter their data and submit it to the server.`}</p>
    <h3>{`fieldset`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<fieldset>`}</code>{` groups related inputs in a form. Fieldsets need a caption and this caption is the legend element. For example, if you have a form with a shipping address and billing address, you can create two groups of inputs with the fieldset element.`}</p>
    <h3>{`legend`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<legend>`}</code>{` element defines a caption for the fieldset.`}</p>
    <h3>{`input`}</h3>
    <p>{`Users can interact with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<input>`}</code>{` elements to enter their data. `}<em parentName="p">{`There are many different kinds of input fields for different types of information`}</em>{`, like text, color, file, etc.`}</p>
    <h3>{`label`}</h3>
    <p>{`Every `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<input>`}</code>{` field needs a caption and this caption is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<label>`}</code>{` element. `}<strong parentName="p">{`You can't replace the `}<code parentName="strong" {...{
          "className": "language-text"
        }}>{`<label>`}</code>{` tag with the placeholder attribute.`}</strong></p>
    <h3>{`select and option`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<select>`}</code>{` is a special kind of input field, with a dropdown menu. This dropdown menu contains `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<option>`}</code>{` tags.`}</p>
    <h3>{`textarea`}</h3>
    <p>{`Users can enter `}<em parentName="p">{`multiple lines of text`}</em>{` in this field`}</p>
    <h3>{`button`}</h3>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<button>`}</code>{` tag creates a clickable element, which looks like a button. It is used to submit forms.`}</p>
    <h2>{`Others`}</h2>
    <h3>{`iframe`}</h3>
    <p>{`You can embed other HTML files into your website with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<iframe>`}</code>{` element. For example, you can insert a song from Spotify or a video from YouTube into your website.`}</p>
    <h3>{`picture`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<picture>`}</code>{` has almost the same purpose as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<img>`}</code>{` element, but you can specify more sources and browsers decide, which is the best for them. If you want to use a new image format, like .avif, (which is not yet widely supported at the time I write this post), you should give the image in another format, like png with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<source>`}</code>{` tag.`}</p>
    <h3>{`source`}</h3>
    <p>{`With this element you can specify multiple sources for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<picture>`}</code>{`, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<audio>`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<video>`}</code>{` elements, like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`picture`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`source`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`srcset`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`image.png`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`source`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`srcset`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`image.webp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`image.avif`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`picture`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3>{`svg`}</h3>
    <p>{`I could describe it in a more complicated way, but `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<svg>`}</code>{` is `}<em parentName="p">{`mostly used to display a vector graphic image`}</em>{`, that you can enlarge or reduce to any size without becoming pixelated.`}</p>
    <h2>{`Learn by playing`}</h2>
 <a href="https://twitter.com/plfstr" rel="noreferrer" target="_blank">Paul Foster</a> built this awesome memory game. Test yourself and beat your score tomorrow. <br /><br />
    <iframe height="300" style={{
      "width": "100%"
    }} scrolling="no" title="HTML Tags Memory Test" src="https://codepen.io/plfstr/embed/zYqQeRw?default-tab=result&editable=true" frameBorder="no" loading="lazy" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href="https://codepen.io/plfstr/pen/zYqQeRw">
  HTML Tags Memory Test</a> by Paul (<a href="https://codepen.io/plfstr">@plfstr</a>)
  on <a href="https://codepen.io">CodePen</a>.
    </iframe><br /><br />
    <p className="takeaway">
    If you pick the correct elements, your code will be easier to read by other developers,<br />
    it will be easier for assistive technology users to use your website,<br />
    the search engines will rank your website higher (SEO).<br />
    Sometimes you should stop and think about whether there is a better HTML element than the one you want to use.
    </p>
    <p>{`If you are missing something or find any inaccuracies, please let me know and I will add or correct it as soon as I can.`}</p>
    <p>{`You can read more about this topic here:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://www.matuzo.at/blog/element-diversity/" rel="noreferrer" target="_blank">Element diversity - Manuel Matuzović</a>
      </li>
      <li parentName="ul">
        <a href="https://developer.mozilla.org/en-US/docs/Glossary/Semantics" rel="noreferrer" target="_blank">Semantics | MDN Web Docs</a>
      </li>
      <li parentName="ul">
        <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element" rel="noreferrer" target="_blank">HTML elements reference | MDN Web Docs</a>
      </li>
      <li parentName="ul">
        <a href="https://developer.mozilla.org/en-US/docs/Learn/Accessibility/HTML" rel="noreferrer" target="_blank">HTML: A good basis for accessibility | MDN Web Docs</a>
      </li>
      <li parentName="ul">
        <a href="https://www.w3schools.com/html/html5_semantic_elements.asp" rel="noreferrer" target="_blank">HTML Semantic Elements - W3Schools Online Web Tutorials</a>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      